import { httpBatchLink, loggerLink } from '@trpc/client'

import { transformer } from '@mntn-dev/utilities'

import { getClientRouteUrl } from '~/utils/client/get-url.ts'

export const trpcClientConfig = {
  links: [
    loggerLink({
      enabled: (opts) =>
        process.env.NODE_ENV === 'development' ||
        (opts.direction === 'down' && opts.result instanceof Error),
    }),
    httpBatchLink({
      url: getClientRouteUrl('api', 'trpc'),
    }),
  ],
  transformer,
}
