'use client'

import type { ReactNode } from 'react'

import { FlagsProvider as ClientSideFlagsProvider } from '@mntn-dev/flags-client'
import { FilteredLogger, Logger } from '@mntn-dev/logger'

const ldLogger = FilteredLogger(Logger('launch-darkly'))

type Props = {
  children: ReactNode
  clientSideID: string
  userKey?: string
  email?: string
  bootstrapFlags?: object
}

function FlagsProvider({
  children,
  clientSideID,
  userKey,
  email,
  bootstrapFlags,
}: Props) {
  return (
    <ClientSideFlagsProvider
      clientSideID={clientSideID}
      context={
        userKey
          ? {
              anonymous: false,
              key: userKey,
              email,
            }
          : {
              anonymous: true,
            }
      }
      options={
        bootstrapFlags
          ? {
              bootstrap: bootstrapFlags,
              logger: ldLogger,
            }
          : { logger: ldLogger }
      }
    >
      {children}
    </ClientSideFlagsProvider>
  )
}

export default FlagsProvider
