'use client'

import { useEffect, type ReactNode } from 'react'

import { useStore, type State } from '~/store/index.ts'

type Props = {
  children: ReactNode
  state: State
}

export function StoreProvider({ children, state }: Props) {
  useEffect(() => {
    useStore.setState(state)
  }, [state])

  return <>{children}</>
}
