'use client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type React from 'react'
import { useState } from 'react'
import { trpcClientConfig } from '../../app/_trpc/trpc-client-config.ts'
import { trpcReactClient } from './trpc-react-client.ts'

export default function TrpcReactProvider({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  const [queryClient] = useState(() => new QueryClient({}))
  const [trpcClient] = useState(() =>
    trpcReactClient.createClient(trpcClientConfig)
  )
  return (
    <trpcReactClient.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} position="top-right" />
      </QueryClientProvider>
    </trpcReactClient.Provider>
  )
}
