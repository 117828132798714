'use client'

import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk'

import type { ClientFlags } from '@mntn-dev/flags-types'

const useFlags = () => {
  const flags = useLDFlags<ClientFlags>()
  return flags
}

export default useFlags
